<div  style="display:flex; justify-content: center;" class="w3-center w3-container">
    <form class="w3-container" #formControl="ngForm">
      <p>
        <label class="w3-text-blue">
          <b>Name</b>
        </label>
        <input required [(ngModel)]="nutrition.name" minlength="2" name="name" type="text" style="text-align: center;"
          class="w3-input w3-border w3-light-grey">
      </p>
      <p><label class="w3-text-blue"><b>Calories</b></label><input required [(ngModel)]="nutrition.calories" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="calories"></p>
      <p><label class="w3-text-blue"><b>Carbohydrate (g)</b></label><input required [(ngModel)]="nutrition.carbohydrate_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="carbohydrate_g"></p>
      <p><label class="w3-text-blue"><b>Fiber (g)</b></label><input required [(ngModel)]="nutrition.fiber_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="fiber_g"></p>
      <p><label class="w3-text-blue"><b>Protein (g)</b></label><input required [(ngModel)]="nutrition.protein_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="protein_g"></p>
      <p><label class="w3-text-blue"><b>Total fat (g)</b></label><input required [(ngModel)]="nutrition.total_fat_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="total_fat_g"></p>
      <p><label class="w3-text-blue"><b>Saturated_fat (g)</b></label><input required [(ngModel)]="nutrition.saturated_fat_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="saturated_fat_g"></p>
      <p><label class="w3-text-blue"><b>Total trans fatty acids (g)</b></label><input required [(ngModel)]="nutrition.fatty_acids_total_trans_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="fatty_acids_total_trans_g"></p>
      <p><label class="w3-text-blue"><b>Cholesterol (mg)</b></label><input required [(ngModel)]="nutrition.cholesterol_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="cholesterol_mg"></p>
      <p><label class="w3-text-blue"><b>Sugars (g)</b></label><input required [(ngModel)]="nutrition.sugars_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="sugars_g"></p>
      <p><label class="w3-text-blue"><b>Water (g)</b></label><input required [(ngModel)]="nutrition.water_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="water_g"></p>
      <p><label class="w3-text-blue"><b>Vitamin A RAE (mcg)</b></label><input required [(ngModel)]="nutrition.vitamin_a_rae_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_a_rae_mcg"></p>
      <p><label class="w3-text-blue"><b>Thiamin - B1 (mg)</b></label><input required [(ngModel)]="nutrition.thiamin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="thiamin_mg"></p>
      <p><label class="w3-text-blue"><b>Riboflavin - B2 (mg)</b></label><input required [(ngModel)]="nutrition.riboflavin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="riboflavin_mg"></p>
      <p><label class="w3-text-blue"><b>Niacin (mg) - B3</b></label><input required [(ngModel)]="nutrition.niacin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="niacin_mg"></p>
      <p><label class="w3-text-blue"><b>Pantothenic acid - B5 (mg)</b></label><input required [(ngModel)]="nutrition.pantothenic_acid_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="pantothenic_acid_mg"></p>
      <p><label class="w3-text-blue"><b>Vitamin B6 (mg)</b></label><input required [(ngModel)]="nutrition.vitamin_b6_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_b6_mg"></p>
      <p><label class="w3-text-blue"><b>Folate - B9 (mcg)</b></label><input required [(ngModel)]="nutrition.folate_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="folate_mcg"></p>
      <p><label class="w3-text-blue"><b>Vitamin B12 (mcg)</b></label><input required [(ngModel)]="nutrition.vitamin_b12_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_b12_mcg"></p>
      <p><label class="w3-text-blue"><b>Choline (mg)</b></label><input required [(ngModel)]="nutrition.choline_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="choline_mg"></p>
      <p><label class="w3-text-blue"><b>Vitamin C (mg)</b></label><input required [(ngModel)]="nutrition.vitamin_c_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_c_mg"></p>
      <p><label class="w3-text-blue"><b>Vitamin D (IU)</b></label><input required [(ngModel)]="nutrition.vitamin_d_IU" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_d_IU"></p>
      <p><label class="w3-text-blue"><b>Vitamin E (mg)</b></label><input required [(ngModel)]="nutrition.vitamin_e_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_e_mg"></p>
      <p><label class="w3-text-blue"><b>Vitamin K (mcg)</b></label><input required [(ngModel)]="nutrition.vitamin_k_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_k_mcg"></p>
      <p><label class="w3-text-blue"><b>Calcium (mg)</b></label><input required [(ngModel)]="nutrition.calcium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="calcium_mg"></p>
      <p><label class="w3-text-blue"><b>Copper (mg)</b></label><input required [(ngModel)]="nutrition.copper_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="copper_mg"></p>
      <p><label class="w3-text-blue"><b>Irom (mg)</b></label><input required [(ngModel)]="nutrition.irom_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="irom_mg"></p>
      <p><label class="w3-text-blue"><b>Magnesium (mg)</b></label><input required [(ngModel)]="nutrition.magnesium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="magnesium_mg"></p>
      <p><label class="w3-text-blue"><b>Manganese (mg)</b></label><input required [(ngModel)]="nutrition.manganese_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="manganese_mg"></p>
      <p><label class="w3-text-blue"><b>Phosphorous (mg)</b></label><input required [(ngModel)]="nutrition.phosphorous_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="phosphorous_mg"></p>
      <p><label class="w3-text-blue"><b>Potassium (mg)</b></label><input required [(ngModel)]="nutrition.potassium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="potassium_mg"></p>
      <p><label class="w3-text-blue"><b>Selenium (mcg)</b></label><input required [(ngModel)]="nutrition.selenium_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="selenium_mcg"></p>
      <p><label class="w3-text-blue"><b>Sodium (mg)</b></label><input required [(ngModel)]="nutrition.sodium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="sodium_mg"></p>
      <p><label class="w3-text-blue"><b>Zink (mg)</b></label><input required [(ngModel)]="nutrition.zink_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="zink_mg"></p>
      <p class="w3-center">
        <button [disabled]="!formControl.valid" (click)="addNutrition()" class="w3-btn w3-green">Create
          <i class="fa fa-check"></i>
        </button>
      </p>
    </form>
  </div>
<div style="display: flex; align-items: center; justify-content: center;" class="w3-padding">
  <div>
    <input type="date" [ngModel] ="dateOfConsumption | date:'yyyy-MM-dd'" (ngModelChange)="onDateChange($event)">
  </div>
</div>

<!--Fixed mobile button-->
<div (click)="openAddForm()" class="mobile-btn">+</div>

<div *ngIf="!isLoaded" class="loader"></div>

<div style="margin-top: 20vh; margin-bottom: 70vh;" class="w3-center w3-padding w3-animate-opacity" *ngIf="nutritions.length == 0 && isLoaded">
  <h3>No data for this date</h3>
  <h5><button class="w3-btn w3-green w3-round-xxlarge" (click)="openAddForm()">
    <i class="fa fa-plus" aria-hidden="true"></i> Add food</button></h5>
</div>

<div *ngIf="isLoaded && nutritions.length > 0" id="divForTable" class="tableFixHead w3-animate-opacity">
  <table>
  <thead>
    <tr class="w3-blue">
      <th class="w3-center"><div style="width: 200px;">Food</div></th>
      <th class="w3-center">Quantity (g)</th>
      <th class="w3-center">Action
          <button class="w3-btn w3-green desktop-button" (click)="openAddForm()">
              <i class="fa fa-plus" aria-hidden="true"></i> Add</button>
      </th>

      <th class="w3-center">Calories</th>
      <th class="w3-center">Carbohydrate (g)</th>
      <th class="w3-center">Fiber (g)</th>
      <th class="w3-center">Protein (g)</th>
      <th class="w3-center">Total fat (g)</th>
      <th class="w3-center">Saturated fat (g)</th>
      <th class="w3-center">Fatty acids total trans (g)</th>
      <th class="w3-center">Cholesterol (mg)</th>
      <th class="w3-center">Sugars (g)</th>
      <th class="w3-center">Water (g)</th>

      <th class="w3-center">Vitamin A RAE (mcg)</th>
      <th class="w3-center">Thiamin - B1 (mg)</th>
      <th class="w3-center">Riboflavin - B2 (mg)</th>
      <th class="w3-center">Niacin - B3 (mg)</th>
      <th class="w3-center">Pantothenic acid - B5 (mg)</th>
      <th class="w3-center">Vitamin B6 (mg)</th>
      <th class="w3-center">Folate - B9 (mcg)</th>
      <th class="w3-center">Vitamin B12 (mcg)</th>
      <th class="w3-center">Choline (mg)</th>
      <th class="w3-center">Vitamin C (mg)</th>
      <th class="w3-center">Vitamin D (IU)</th>
      <th class="w3-center">Vitamin E (mg)</th>
      <th class="w3-center">Vitamin K (mcg)</th>

      <th class="w3-center">Calcium (mg)</th>
      <th class="w3-center">Copper (mg)</th>
      <th class="w3-center">Iron (mg)</th>
      <th class="w3-center">Magnesium (mg)</th>
      <th class="w3-center">Manganese (mg)</th>
      <th class="w3-center">Phosphorous (mg)</th>
      <th class="w3-center">Potassium (mg)</th>
      <th class="w3-center">Selenium (mcg)</th>
      <th class="w3-center">Sodium (mg)</th>
      <th class="w3-center">Zink (mg)</th>
    </tr>
	</thead>
    <tr *ngFor="let nutrition of nutritions">
      <td class="w3-center"><div style="width: 200px;">{{nutrition.nutrition.name}}</div></td>
      <td style="white-space: nowrap;" class="w3-center"><input attr.id="{{nutrition._id}}" value="{{nutrition.quantity}}" type="number" style="width: 50pt;"> g</td>
      <td class="w3-center">
        <div class="w3-center">
          <button style="width: 100px; height: 38px;" (click)="updateUserNutrition(nutrition)" class="w3-btn w3-blue"><i class="fa fa-edit"> Update</i></button>
        </div>
		<div style="padding-top: 4px;"></div>
		<div class="w3-center">
          <button style="width: 100px; height: 38px;" (click)="deleteUserNutrition(nutrition._id)" class="w3-btn w3-red"><i class="fa fa-trash"> Delete</i></button>
        </div>
      </td>

      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.calories).toFixed(2)}} <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.calories/dri.calories*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.carbohydrate_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.carbohydrate_g/dri.carbohydrate_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.fiber_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.fiber_g/dri.fiber_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.protein_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.protein_g/dri.protein_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.total_fat_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.total_fat_g/dri.total_fat_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.saturated_fat_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.saturated_fat_g/dri.saturated_fat_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.fatty_acids_total_trans_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.fatty_acids_total_trans_g/dri.fatty_acids_total_trans_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.cholesterol_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.cholesterol_mg/dri.cholesterol_mg*100).toFixed(2)}} %) </span></td>
	    <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.sugars_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.sugars_g/dri.sugars_g*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.water_g).toFixed(2)}} g <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.water_g/dri.water_g*100).toFixed(2)}} %) </span></td>

      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_a_rae_mcg).toFixed(2)}} mcg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_a_rae_mcg/dri.vitamin_a_rae_mcg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.thiamin_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.thiamin_mg/dri.thiamin_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.riboflavin_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.riboflavin_mg/dri.riboflavin_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.niacin_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.niacin_mg/dri.niacin_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.pantothenic_acid_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.pantothenic_acid_mg/dri.pantothenic_acid_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_b6_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_b6_mg/dri.vitamin_b6_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.folate_mcg).toFixed(2)}} mcg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.folate_mcg/dri.folate_mcg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_b12_mcg).toFixed(2)}} mcg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_b12_mcg/dri.vitamin_b12_mcg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.choline_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.choline_mg/dri.choline_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_c_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_c_mg/dri.vitamin_c_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_d_IU).toFixed(2)}} IU <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_d_IU/dri.vitamin_d_IU*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_e_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_e_mg/dri.vitamin_e_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.vitamin_k_mcg).toFixed(2)}} mcg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.vitamin_k_mcg/dri.vitamin_k_mcg*100).toFixed(2)}} %) </span></td>

      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.calcium_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.calcium_mg/dri.calcium_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.copper_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.copper_mg/dri.copper_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.irom_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.irom_mg/dri.irom_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.magnesium_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.magnesium_mg/dri.magnesium_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.manganese_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.manganese_mg/dri.manganese_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.phosphorous_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.phosphorous_mg/dri.phosphorous_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.potassium_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.potassium_mg/dri.potassium_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.selenium_mcg).toFixed(2)}} mcg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.selenium_mcg/dri.selenium_mcg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.sodium_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.sodium_mg/dri.sodium_mg*100).toFixed(2)}} %) </span></td>
      <td style="white-space: nowrap;" class="w3-center">{{(nutrition.quantity/100*nutrition.nutrition.zink_mg).toFixed(2)}} mg <span class="w3-text-green">({{(nutrition.quantity/100*nutrition.nutrition.zink_mg/dri.zink_mg*100).toFixed(2)}} %) </span></td>
    </tr>
	<tfoot>
	<tr style="background-color: #c2c1be;">
      <th class="w3-center"><div style="width: 200px;">Total</div></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumQuantity}} g</th>
      <th style="white-space: nowrap;" class="w3-center"> - </th>

      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.calories.toFixed(2)}} <span class="w3-text-green">({{(sumNutritions.calories/dri.calories*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.carbohydrate_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.carbohydrate_g/dri.carbohydrate_g*100).toFixed(2)}} %)</span></th>
	    <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.fiber_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.fiber_g/dri.fiber_g*100).toFixed(2)}} %)</span></th>
	    <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.protein_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.protein_g/dri.protein_g*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.total_fat_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.total_fat_g/dri.total_fat_g*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.saturated_fat_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.saturated_fat_g/dri.saturated_fat_g*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.fatty_acids_total_trans_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.fatty_acids_total_trans_g/dri.fatty_acids_total_trans_g*100).toFixed(2)}} %)</span></th>
	    <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.cholesterol_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.cholesterol_mg/dri.cholesterol_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.sugars_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.sugars_g/dri.sugars_g*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.water_g.toFixed(2)}} g <span class="w3-text-green">({{(sumNutritions.water_g/dri.water_g*100).toFixed(2)}} %)</span></th>

      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_a_rae_mcg.toFixed(2)}} mcg <span class="w3-text-green">({{(sumNutritions.vitamin_a_rae_mcg/dri.vitamin_a_rae_mcg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.thiamin_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.thiamin_mg/dri.thiamin_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.riboflavin_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.riboflavin_mg/dri.riboflavin_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.niacin_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.niacin_mg/dri.niacin_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.pantothenic_acid_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.pantothenic_acid_mg/dri.pantothenic_acid_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_b6_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.vitamin_b6_mg/dri.vitamin_b6_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.folate_mcg.toFixed(2)}} mcg <span class="w3-text-green">({{(sumNutritions.folate_mcg/dri.folate_mcg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_b12_mcg.toFixed(2)}} mcg <span class="w3-text-green">({{(sumNutritions.vitamin_b12_mcg/dri.vitamin_b12_mcg*100).toFixed(2)}} %)</span></th>
	    <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.choline_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.choline_mg/dri.choline_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_c_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.vitamin_c_mg/dri.vitamin_c_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_d_IU.toFixed(2)}} IU <span class="w3-text-green">({{(sumNutritions.vitamin_d_IU/dri.vitamin_d_IU*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_e_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.vitamin_e_mg/dri.vitamin_e_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.vitamin_k_mcg.toFixed(2)}} mcg <span class="w3-text-green">({{(sumNutritions.vitamin_k_mcg/dri.vitamin_k_mcg*100).toFixed(2)}} %)</span></th>

      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.calcium_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.calcium_mg/dri.calcium_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.copper_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.copper_mg/dri.copper_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.irom_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.irom_mg/dri.irom_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.magnesium_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.magnesium_mg/dri.magnesium_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.manganese_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.manganese_mg/dri.manganese_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.phosphorous_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.phosphorous_mg/dri.phosphorous_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.potassium_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.potassium_mg/dri.potassium_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.selenium_mcg.toFixed(2)}} mcg <span class="w3-text-green">({{(sumNutritions.selenium_mcg/dri.selenium_mcg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.sodium_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.sodium_mg/dri.sodium_mg*100).toFixed(2)}} %)</span></th>
      <th style="white-space: nowrap;" class="w3-center">{{sumNutritions.zink_mg.toFixed(2)}} mg <span class="w3-text-green">({{(sumNutritions.zink_mg/dri.zink_mg*100).toFixed(2)}} %)</span></th>
    </tr>
	</tfoot>
  </table>
</div>


<div class="w3-container w3-center">
  <div id="id01" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
        <span (click)="closedAddFoodDialog()" onclick="document.getElementById('id01').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>Search food</h2>
      </header>
      <div class="w3-container">
        <app-search-and-add-nutrition></app-search-and-add-nutrition>
      </div>
    </div>
  </div>
</div>

<div id="register-form" class="w3-center w3-padding" style="margin:0 auto; max-width: 600px;">
    <div class="w3-card-4">
      <div class="w3-container w3-blue">
        <h2>Register form</h2>
      </div>
      <form class="w3-container" #formControl="ngForm">
        <p>
          <label class="w3-text-blue">
            <b>First name</b>
          </label>
          <input required [(ngModel)]="firstName" minlength="2" maxlength="40" name="first" type="text" style="text-align: center;"
            class="w3-input w3-border w3-light-grey">
        </p>
        <p>
          <label class="w3-text-blue">
            <b>Last name</b>
          </label>
          <input required [(ngModel)]="lastName" minlength="2" maxlength="40" name="last" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
        </p>
        <p>
          <label class="w3-text-blue">
            <b>Email</b>
          </label>
          <input required [(ngModel)]="credentials.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" minlength="1" maxlength="40"
            name="email" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
        </p>
        <p>
          <label class="w3-text-blue">
            <b>Password</b>
          </label>
          <label class="w3-text-grey" style="font-style: italic">
          </label>
          <input required [(ngModel)]="credentials.password" minlength="8" maxlength="20" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^_+\-=\[\]{};%*?&()])[A-Za-z\d@$!#^_+\-=\[\]{};%*?&()]{8,20}$" name="password" type="password" style="text-align: center;"
            class="w3-input w3-border w3-light-grey">
            <label class="w3-text-grey" style="font-style: italic">
                <b style="font-size: 9pt;">Minimum 8 and maximum 20 characters. It must contain at least one lowercase and uppercase letter, a number as well
                  as a special character.
                </b>
              </label>
        </p>

        <p>
          <label class="w3-text-blue">
            <b>Confirmation password</b>
          </label>
          <label class="w3-text-grey" style="font-style: italic">
          </label>
          <input required [(ngModel)]="confirmationPassword" minlength="8" maxlength="20" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^_+\-=\[\]{};%*?&()])[A-Za-z\d@$!#^_+\-=\[\]{};%*?&()]{8,20}$" name="password" type="password" style="text-align: center;"
            class="w3-input w3-border w3-light-grey">
          <label class="w3-text-grey" style="font-style: italic">
            <b style="font-size: 9pt;">Enter the new password again to confirm it.</b>
          </label>
        </p>
        <div class="text-center">
        <re-captcha class="g-recaptcha" required id="recaptcha" name="recaptcha" (resolved)="resolved($event)"></re-captcha>
      </div>
        <p class="w3-center">
          <button id="register-btn" [disabled]="!formControl.valid" (click)="register()" class="w3-btn w3-blue">Register</button>
        </p>
      </form>
    </div>
  </div>


<div id="resend-verification" class="w3-center w3-padding-64 w3-animate-opacity" style="margin:0 auto; max-width: 600px;">
  <p>A verification email has been sent to: <b>{{credentials.email}}</b><br>
    If the email is not in your Inbox section, check for it in the Spam sections. If you have not recevied
    a verification mail, click on the button below to resend a new one. The verification link will expire
    in 24 hours.
  </p>
  <button class="w3-btn w3-blue" (click)="resend()">Resend verification email</button>
  <p>
  </p>
</div>

<div>
  <header class="w3-display-container w3-content" style="line-height: 1; max-width:1500px;" id="home">
    <img style="border-radius: 0px; box-shadow: 0 0 0 0;" class="w3-image" src="../../../assets/bg.jpg" alt="Food table" width="1500" height="800">
    <div style="background-color: rgba(255,255,255, 0.8);" class="w3-display-middle w3-center">
      <h1 style="font-size:8vw;"><b>Dietary habits</b></h1>
      <p><button class="w3-btn w3-blue" routerLink="/register">Sign up</button></p>
    </div>
  </header>
</div>

<div class="w3-padding-16 w3-container">
  <div class="w3-content">
    <h2>Track what you eat</h2>
    <h5>
      The <b>Dietary habits app</b> allows you to input all of the food you ate (or are going to eat) and show you the
      nutritional gain from it, in detail.
      Our database has 8000+ foods and it is constantly growing thanks to user inputs. Every food is broken down into 3
      segments:
    </h5>

    <div class="row">
      <div class="column" style="background-color:#4CAF50;">
        <h2>Macronutrients</h2>
        <div>
          <ul style="line-height: 1.2;">
            <li>Calories</li>
            <li>Carbohydrate</li>
            <li>Fiber</li>
            <li>Protein</li>
            <li>Total fat</li>
            <li>Saturated fat</li>
            <li>Fatty acids total trans</li>
            <li>Cholesterol</li>
            <li>Sugars</li>
            <li>Water</li>
          </ul>
        </div>
      </div>
      <div class="column" style="background-color:#ff9800;">
        <h2>Vitamins</h2>
        <div>
          <ul style="line-height: 1.2;">
            <li>Vitamin A RAE </li>
            <li>Thiamin - B1</li>
            <li>Riboflavin - B2</li>
            <li>Niacin - B3</li>
            <li>Pantothenic acid - B5</li>
            <li>Vitamin B6</li>
            <li>Folate - B9 </li>
            <li>Vitamin B12 </li>
            <li>Choline</li>
            <li>Vitamin C</li>
            <li>Vitamin D</li>
            <li>Vitamin E</li>
            <li>Vitamin K </li>
          </ul>
        </div>
      </div>
      <div class="column" style="background-color:#2196F3;">
        <h2>Minerals</h2>
        <div>
          <ul style="line-height: 1.2;">
            <li>Calcium</li>
            <li>Copper</li>
            <li>Iron</li>
            <li>Magnesium</li>
            <li>Manganese</li>
            <li>Phosphorous</li>
            <li>Potassium</li>
            <li>Selenium </li>
            <li>Sodium</li>
            <li>Zink</li>
          </ul>
        </div>
      </div>
    </div>

    <h2>Easily search and add consumed food</h2>
    <h5>
      Multiword search that makes finding specific foods easy and quick. The consumed food can be added for any date, which can be selected at the top of the modal dialog.
    </h5>
    <div class="w3-center">
      <img srcset="../../../assets/gifs/my-diet-mobile.gif 270w,
                      ../../../assets/gifs/my-diet.gif 800w" sizes="(max-width: 800px) 270px,
                      800px" src="../../../assets/gifs/my-diet.gif" alt="Adding food">
    </div>
    <h5 class="image-caption">Adding eaten food quantity</h5>
  </div>
</div>

<div class="w3-light-grey w3-padding-16 w3-container">
  <div class="w3-content">
    <h2>Custom DRI</h2>
    <h5>The app allows you to adjust the Dietary Reference Intake (DRI)
      based on your preferences.
      You can set the minimal DRI you want to accomplish in any of the aforementioned nutritions as well as the maximum
      or Upper Intake (UI) values.
      Also, you can create multiple DRI presets and easily switch between them.</h5>
    <div class="w3-center">
      <img srcset="../../../assets/gifs/my-dri-mobile.gif 228w,
                      ../../../assets/gifs/my-dri.gif 800w" sizes="(max-width: 800px) 228px,
                      800px" src="../../../assets/gifs/my-dri.gif" alt="Using custom DRIs">
    </div>
    <h5 class="image-caption">Using custom DRIs</h5>
  </div>
</div>

<div class="w3-padding-16 w3-container">
  <div class="w3-content">
    <h2>Analytics</h2>
    <h5>The analytics section shows you in a graphic way how you are
      doing in regards to all of the
      nutrition values. This is based on the set DRI value and shows daily intakes as well as per certain nutrition
      from foods.</h5>

    <div class="w3-center">
      <img srcset="../../../assets/gifs/stats-mobile.gif 296w,
                    ../../../assets/gifs/stats.gif 800w" sizes="(max-width: 800px) 296px,
                    800px" src="../../../assets/gifs/stats.gif" alt="Getting the stats">
    </div>

    <h5 class="image-caption">Getting the stats</h5>

    <div style="background-color: lightgray; border-radius: 5px;;" class="w3-padding w3-center">
      <h3>If you haven't already, sign up</h3>
      <p><button class="w3-btn w3-blue" routerLink="/register">Sign up</button></p>
    </div>

  </div>
</div>
<div class="w3-container w3-center">

  <div id="id01" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
	          <span class="w3-button w3-display-topleft" (click)="clearDialog()">Clear
          <i class="fa fa-eraser"></i>
        </span>
        <span onclick="document.getElementById('id01').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>New user form</h2>
      </header>
      <div class="w3-container">

        <form class="w3-container" #formControl="ngForm">
          <p>
            <label class="w3-text-blue">
              <b>First name</b>
            </label>
            <input required [(ngModel)]="firstName" minlength="2" maxlength="40" name="first" type="text" style="text-align: center;"
              class="w3-input w3-border w3-light-grey">
          </p>
          <p>
            <label class="w3-text-blue">
              <b>Last name</b>
            </label>
            <input required [(ngModel)]="lastName" minlength="2" maxlength="40" name="last" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          </p>
          <p>
            <label class="w3-text-blue">
              <b>Email</b>
            </label>
            <input required [(ngModel)]="credentials.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" minlength="1" maxlength="40"
              name="email" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          </p>
          <p>
            <label class="w3-text-blue">
              <b>Password</b>
            </label>
            <label class="w3-text-grey" style="font-style: italic">
            </label>
            <input required [(ngModel)]="credentials.password" minlength="8" maxlength="20" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$"
              name="password" type="password" style="text-align: center;" class="w3-input w3-border w3-light-grey">
            <label class="w3-text-grey" style="font-style: italic">
              <b style="font-size: 9pt;">Minimum 8 and maximum 20 characters. It must contain at least one lowercase and uppercase letter, a number
                as well as a special character.
              </b>
            </label>
          </p>

          <p>
            <label class="w3-text-blue">
              <b>Confirmation password</b>
            </label>
            <label class="w3-text-grey" style="font-style: italic">
            </label>
            <input required [(ngModel)]="confirmationPassword" minlength="8" maxlength="20" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,20}$"
              name="password" type="password" style="text-align: center;" class="w3-input w3-border w3-light-grey">
            <label class="w3-text-grey" style="font-style: italic">
              <b style="font-size: 9pt;">Enter the new password again to confirm it.</b>
            </label>
          </p>

          <p class="w3-center">
            <button [disabled]="!formControl.valid" (click)="addUser()" class="w3-btn w3-green">Create
              <i class="fa fa-check"></i>
            </button>
          </p>

        </form>

      </div>
    </div>
  </div>
</div>

<div class="w3-container w3-center">

  <div id="id02" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
        <span onclick="document.getElementById('id02').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>Update user form</h2>
      </header>
      <div class="w3-container">
	<form class="w3-container" #formControlEdit="ngForm">
          <p>
            <label class="w3-text-blue">
              <b>Name</b>
            </label>
            <input required [(ngModel)]="selectedUser.name" name="name" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          </p>
          <p>
            <label class="w3-text-blue">
              <b>Email</b>
            </label>
            <input required [(ngModel)]="selectedUser.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email" type="text"
              style="text-align: center;" class="w3-input w3-border w3-light-grey">
            <label class="w3-text-grey" style="font-style: italic">
              <b>someone@domain.domain</b>
            </label>
          </p>

          <p>
            <label class="w3-text-blue">
              <b>Role</b>
            </label>
            <select required [(ngModel)]="selectedUser.role" style="text-align: center;" class="w3-center w3-input w3-border w3-light-grey w3-select w3-border"
              name="option">
              <option value="admin">Admin</option>
              <option value="user">User</option>
            </select>
          </p>

          <p class="w3-center">
            <button [disabled]="!formControlEdit.valid" (click)="updateUser()" class="w3-btn w3-blue">Update
              <i class="fa fa-check"></i>
            </button>
          </p>
          <p>
              <button (click)="resetPassword()" class="w3-btn w3-black">Reset Password
              <i class="fa fa-key"></i>
            </button>
          </p>
        </form>

      </div>
    </div>
  </div>
</div>


<div id="id03" class="modal">
  <span onclick="document.getElementById('id03').style.display='none'" class="close" title="Close Modal">×</span>
  <form class="modal-content">
    <div class="container">
      <h1>Delete Account</h1>
      <p>Name: {{selectedUser?.name}}</p>
      <p>Email: {{selectedUser?.email}}</p>
      <p>Role: {{selectedUser?.role}}</p>
      <p>Are you sure you want to delete this account?</p>

      <div class="clearfix">
        <button type="button" onclick="document.getElementById('id03').style.display='none'" class="cancelbtn">Cancel</button>
        <button type="button" (click)="deleteUser()" class="deletebtn">Delete</button>
      </div>
    </div>
  </form>
</div>

<div class="search-container w3-center w3-padding">
    <input [(ngModel)]="search" type="text" placeholder="Search.." name="search">
  <button (click)="getUsers()" style="margin-right: 15pt;" class="w3-button">
    <i class="fa fa-search"></i>
  </button>
  <button class="w3-button w3-green" onclick="document.getElementById('id01').style.display='block'">
    <i class="fa fa-user fa-fw"></i>
    <i class="fa fa-plus"></i>
  </button>
</div>

<div>
  <div class="row">
    <div class="column w3-card w3-hover-blue" *ngFor="let user of users">
      <h3 style="text-align: center">{{user.name}}</h3>
      <p style="text-align: center">{{user.email}}</p>
      <p style="text-align: center">Role: {{user.role}}</p>
      <div class="w3-center w3-padding">
                <button style="margin-right: 5pt" class="w3-button w3-black" (click)="selectUser(2, user)">
          <i class="fa fa-edit"></i>
        </button>
        <button class="w3-button w3-red" (click)="selectUser(3, user)">
          <i class="fa fa-trash-o"></i>
        </button>
      </div>
    </div>
  </div>
</div>

<div *ngIf="search===''" class="w3-center w3-padding">
  <div class="w3-bar w3-border w3-round">
    <button (click)="previousPage()" [disabled]="currentPage <= 0" class="w3-button">&laquo;</button>
    <p style="display: inline; margin-left: 1vw; margin-right: 1vw;">Page {{currentPage + 1}} of {{numberOfPages}}</p>
    <button (click)="nextPage()" [disabled]="currentPage >= numberOfPages - 1" class="w3-button">&raquo;</button>
    <span style="margin-left: 0.5vw">Jump to </span>
    <input style="width: 6vw; margin-right: 1vw;" type="number" (keyup.enter)="jumpToPage($event)">
    <span style="margin-left: 0.5vw;">Per page
      <select style="margin-right: 1vw" [(ngModel)]="currnetLimit" name="option" (ngModelChange)="onLimitChange($event)">
        <option value="4">4</option>
        <option value="8">8</option>
        <option value="12">12</option>
      </select>
    </span>

  </div>
</div>
<div style="display: table; margin-top: 10px; margin-left: auto; margin-right: auto; margin-bottom: 10px;">
  <div id="date_label" style="font-size: 20px; display: table-cell;" class="w3-center w3-padding">Date </div>
  <div>
    <input type="date" [ngModel] ="dateOfConsumption | date:'yyyy-MM-dd'" (ngModelChange)="onDateChange($event)">
  </div>
</div>

<div *ngIf="!isLoaded" class="loader"></div>

<h3 class="w3-center w3-padding" *ngIf="nutritions.length == 0 && isLoaded">No data for this date</h3>

<div id="id01" class="w3-modal">
  <div class="w3-modal-content w3-animate-zoom w3-card-4">
    <header id="myContainer" class="w3-container w3-text-white">
      <span onclick="document.getElementById('id01').style.display='none'"
      class="w3-button w3-display-topright">&times;</span>
      <h3 id="myDiv"></h3>
    </header>
    <h5 id="percentage" class="w3-center"></h5>
    <h3 class="w3-center">Distribution from food</h3>
    <div style="display: flex; align-items: center; justify-content: center;">
      <div id='pie-chart' style='width:100%; height:100%;'></div>
    </div>
  </div>
</div>

<div class="wrapper">
  <div class="graph-div"><div class="canvas-graph" id="macronutrients"></div></div>
  <div class="graph-div"><div class="canvas-graph" id="vitamins"></div></div>
  <div class="graph-div"><div class="canvas-graph" id="minerals"></div></div>
</div>

<div class="wrapper">
  <div class="box" style="background-color:#2196F3;"></div><div> - Below DRI</div>
  <div class="box" style="background-color:#4CAF50;"></div><div> - Between DRI and UI</div>
  <div class="box" style="background-color:#f44336;"></div><div> - Above UI</div>
</div>

export class Nutrition {
  _id?: String;
  name: String = "";
  calories: Number = 0;
  carbohydrate_g: Number = 0;
  fiber_g: Number = 0;
  protein_g: Number = 0;
  total_fat_g: Number = 0;
  saturated_fat_g: Number = 0;
  fatty_acids_total_trans_g: Number = 0;
  cholesterol_mg: Number = 0;
  sugars_g: Number = 0;
  water_g: Number = 0;
  vitamin_a_rae_mcg: Number = 0;
  thiamin_mg: Number = 0;
  riboflavin_mg: Number = 0;
  niacin_mg: Number = 0;
  pantothenic_acid_mg: Number = 0;
  vitamin_b6_mg: Number = 0;
  folate_mcg: Number = 0;
  vitamin_b12_mcg: Number = 0;
  choline_mg: Number = 0;
  vitamin_c_mg: Number = 0;
  vitamin_d_IU: Number = 0;
  vitamin_e_mg: Number = 0;
  vitamin_k_mcg: Number = 0;
  calcium_mg: Number = 0;
  copper_mg: Number = 0;
  irom_mg: Number = 0;
  magnesium_mg: Number = 0;
  manganese_mg: Number = 0;
  phosphorous_mg: Number = 0;
  potassium_mg: Number = 0;
  selenium_mcg: Number = 0;
  sodium_mg: Number = 0;
  zink_mg: Number = 0;
}

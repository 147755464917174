<head>
  <meta name="viewport" content="width=device-width, initial-scale=1">
</head>

<div class="topnav" id="myTopnav">
  <a routerLink="/home" class="active"><img src="../favicon.ico" width="20" height="20"> DH</a>
  <a *ngIf="!auth.isLoggedIn()" (click)="collapse()" routerLink="/sign-in" id="sign-in"><i class="fa fa-sign-in"></i> Sign in</a>
  <a *ngIf="auth.isAdmin()" (click)="collapse()" routerLink="/users"><i class="fa fa-users"></i> Users</a>
  <a *ngIf="auth.isAdmin()" (click)="collapse()" routerLink="/nutrition"><i class="fa fa-cutlery"></i> Nutritions</a>
  <a *ngIf="auth.isLoggedIn()" (click)="collapse()" routerLink="/my-diet"><i class="fa fa-cutlery"></i> My diet</a>
  <a *ngIf="auth.isLoggedIn()" (click)="collapse()" routerLink="/my-dri"><i class="fa fa-dot-circle-o"></i> My DRI</a>
  <a *ngIf="auth.isLoggedIn()" (click)="collapse()" routerLink="/stats"><i class="fa fa-bar-chart"></i> Stats</a>
  <a *ngIf="auth.isLoggedIn()" (click)="collapse()" routerLink="/create-food"><i style="font-size: x-small; margin-right: 2pt;" class="fa fa-plus"></i><i class="fa fa-apple" aria-hidden="true"></i> Create food</a>
  <a *ngIf="auth.isLoggedIn()" (click)="collapse()" routerLink="/profile"><i class="fa fa-user"></i> My profile</a>
  <a *ngIf="auth.isLoggedIn()" (click)="collapse()" (click)="auth.logout()" id="logout"><i class="fa fa-sign-out"></i> Logout</a>
  <a style="font-size:15px;" class="icon" (click)="myFunction()">
    <i class="fa fa-bars"></i>
  </a>
</div>

<router-outlet></router-outlet>

<footer class="footer-distributed">
  <div class="footer-left">
    <h3 style="color: white;">Dietary habits</h3>
    <p class="footer-links">
      <a routerLink="/home">Home</a> ·
      <a routerLink="/register">Sign-up</a> ·
      <a routerLink="/sign-in">Sign-in</a> ·
      <a routerLink="/my-diet">My-diet</a> ·
      <a routerLink="/my-dri">My-dri</a> ·
      <a routerLink="/stats">Stats</a>
    </p>
    <p class="footer-company-name">someone &copy; 2022</p>
    <a style="margin-top: 25px;" class="w3-button w3-black" (click)="toTop()"><i class="fa fa-arrow-up"></i>To top</a>
  </div>
 
  <div class="footer-center">
    <div>
      <i class="fa fa-map-marker"></i>
      <p><span>John Kennedy 36</span> New Belgrade, Serbia</p>
    </div>
    <div>
      <i class="fa fa-phone"></i>
      <p>+38165515524</p>
    </div>
    <div>
      <i class="fa fa-envelope"></i>
      <p><a href="mailto:contact@dhab.com">contact@dhab.com</a></p>
    </div>
  </div>
 
  <div class="footer-right">
    <p class="footer-company-about">
      <span>About the app</span>
      Dietary habits is an app for tracking nutritional intake of macronutrients, vitamins and minerals from consumed food.
    </p>
    <div class="footer-icons">
      <a href="#"><i class="fa fa-facebook"></i></a>
      <a href="#"><i class="fa fa-twitter"></i></a>
      <a href="#"><i class="fa fa-linkedin"></i></a>
      <a href="#"><i class="fa fa-github"></i></a>
    </div>
  </div>
</footer>
 
 

<div *ngIf="!isLoaded" class="loader"></div>

<div *ngIf="isLoaded" class="w3-animate-opacity w3-center w3-padding" style="margin:0 auto; max-width: 600px;">
    <div class="w3-card-4">
      <div class="w3-container w3-blue">
        <h2>My profile</h2>
      </div>
      <form class="w3-container" #formControl="ngForm">

          <p>
              <label class="w3-text-blue">
                <b>Name</b>
              </label>
              <input required [(ngModel)]="details.name" name="name" type="text" style="text-align: center;"
                class="w3-input w3-border w3-light-grey">
            </p>
        <p>
          <label class="w3-text-blue">
            <b>Email</b>
          </label>
          <input required [(ngModel)]="details.email" pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,}$" name="email" type="text" style="text-align: center;"
            class="w3-input w3-border w3-light-grey">
          <label class="w3-text-grey" style="font-style: italic">
            <b>someone@domain.domain</b>
          </label>
        </p>
        <p>
          <label class="w3-text-blue">
            <b>New password</b>
          </label>
          <input [(ngModel)]="newPassword" name="newpassword" minlength="8" maxlength="20" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^_+\-=\[\]{};%*?&()])[A-Za-z\d@$!#^_+\-=\[\]{};%*?&()]{8,20}$"
            type="password" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          <label class="w3-text-grey" style="font-style: italic">
            <b style="font-size: 9pt;">Minimum 8 and maximum 20 characters. It must contain at least one lowercase and uppercase letter, a number as well
              as a special character.
              <span style="color: red;"> (Only if you want to change the current password)</span>
            </b>
          </label>
        </p>
        <p>
          <label class="w3-text-blue">
            <b>Confirmation password</b>
          </label>
          <input [(ngModel)]="confirmationPassword" name="confirmationpassword" minlength="8" maxlength="20" pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!#^_+\-=\[\]{};%*?&()])[A-Za-z\d@$!#^_+\-=\[\]{};%*?&()]{8,20}$"
            type="password" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          <label class="w3-text-grey" style="font-style: italic">
              <b style="font-size: 9pt;">Enter the new password again to confirm it.
                  <span style=" color: red;"> (Only if you want to change the current password)</span>
            </b>
          </label>
        </p>

        <p>
            <label class="w3-text-blue">
              <b>Current password <span style=" color: red;"> *</span></b>
            </label>
            <label class="w3-text-grey" style="font-style: italic">
            </label>
            <input required [(ngModel)]="currentPassword" minlength="1" maxlength="40" name="oldpassword" type="password" style="text-align: center;"
              class="w3-input w3-border w3-light-grey">
          </p>

        <p class="w3-center">
          <button [disabled]="!formControl.valid" (click)="save()" class="w3-btn w3-blue">Save changes</button>
        </p>
      </form>
    </div>
  </div>

<div class="w3-container w3-center">
    <div id="id01" class="w3-modal">
        <div class="w3-modal-content w3-animate-top w3-card-4">
            <header class="w3-container w3-blue">
                <span onclick="document.getElementById('id01').style.display='none'"
                    class="w3-button w3-display-topright">&times;</span>
                <h2>Search food</h2>
            </header>
            <div class="w3-container">
                <div style="display: flex; align-items: center; justify-content: center;" class="w3-padding">
                    <form style="max-width: 250px; margin: auto;" #formControlSearch="ngForm">
                        <input style="max-width: 150px; margin: 0; float: left;" [(ngModel)]="search" class="w3-input"
                            required minlength="2" type="text" placeholder="Search by name" name="search">
                        <button [disabled]="!formControlSearch.valid" (click)="getNutritions()" class="w3-button">
                            <i class="fa fa-search"></i>
                        </button>
                        <div class="tooltip">
                            <i style="margin-left: 5pt; font-size: 13pt;" class="fa fa-info-circle"></i>
                            <span class="tooltiptext">Enter at least 2 characters. Resulting food contains all words
                                (divided by spaces)
                                in its name.</span>
                        </div>
                    </form>
                </div>

                <div *ngIf="!isLoaded" class="loader"></div>

                <div *ngIf="isLoaded">
                    <div class="row">
                        <div class="column w3-card w3-hover-blue" *ngFor="let nutrition of nutritions">
                            <p class="scrollable-text w3-padding" style="text-align: center;">{{nutrition.name}}</p>
                            <div class="w3-center">
                                <label style="margin-right:5px;">Quantity</label>
                                <input attr.id="{{nutrition._id}}" type="number" style="width: 50pt;">
                                <label style="margin-left:5px;">(g)</label>
                            </div>
                            <div class="w3-center w3-padding">
                                <button style="margin-right: 5pt" class="w3-button w3-green"
                                    (click)="addIngredient(nutrition)">
                                    Add <i class="fa fa-check"></i>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<form #formControlName="ngForm">

    <div class="centered-input">
        <div style="min-width: 200px; max-width: 40vw; display: flex; align-items: center;" class="w3-padding">
            <input [(ngModel)]="compositeName" class="w3-input" required minlength="5" type="text" placeholder="Composite name" name="compositeName">
            <div  class="tooltip">
                <i style="margin-left: 5pt; font-size: 13pt;" class="fa fa-info-circle"></i>
                <span class="tooltiptext">Enter at least 5 characters.</span>
            </div>
        </div>
    </div>
      
    <div *ngIf="ingredients.length > 0" id="divForTable" class="w3-animate-opacity">
        <table>
            <thead>
                <tr class="w3-blue">
                    <th class="w3-center">Name</th>
                    <th class="w3-center">Quantity</th>
                    <th class="w3-center">Action
                        <button class="w3-btn w3-green" (click)="openAddForm()">
                            <i class="fa fa-plus" aria-hidden="true"></i></button>
                    </th>
                </tr>
            </thead>
            <tr *ngFor="let ingredient of ingredients">
                <td class="w3-center">
                    <div>{{ingredient.nutrition.name}}</div>
                </td>
                <td class="w3-center">
                    <div>{{ingredient.ingredient_quantity}}</div>
                </td>
                <td class="w3-center">
                    <div style="padding-top: 4px;"></div>
                    <div class="w3-center">
                        <button (click)="removeIngredient(ingredient.nutrition._id)" class="w3-btn w3-red">
                            <i class="fa fa-trash"></i>
                        </button>
                    </div>
                </td>
            </tr>
        </table>
    </div>
    <div class="w3-center" style="margin-bottom: 25px;">
    <button id="create-btn" *ngIf="ingredients.length > 1" [disabled]="!formControlName.valid" (click)="createCompositeFood()" class="w3-btn w3-green">
        <i class="fa fa-plus"></i> Create food
    </button>
    </div>

</form>

<div class="w3-center" style="margin-top: 15vh; margin-bottom: 15vh;" *ngIf="ingredients.length === 0">
    <h4>No ingredients for now.</h4>
    <h4>To create composite food you need to add at least 2 ingredients.
        <button class="w3-btn w3-green" (click)="openAddForm()">
            <i class="fa fa-plus" aria-hidden="true"></i>
        </button>
    </h4>
</div>
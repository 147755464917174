<div class="w3-container w3-center">
  <div id="id01" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
        <span class="w3-button w3-display-topleft" (click)="clearDialog()">Clear
          <i class="fa fa-eraser"></i>
        </span>
        <span onclick="document.getElementById('id01').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>New nutrition form</h2>
      </header>
      <div class="w3-container">
        <form class="w3-container" #formControl="ngForm">
          <p>
            <label class="w3-text-blue">
              <b>Name</b>
            </label>
            <input required [(ngModel)]="nutrition.name" minlength="2" name="name" type="text" style="text-align: center;"
              class="w3-input w3-border w3-light-grey">
          </p>
          <p><label class="w3-text-blue"><b>calories</b></label><input required [(ngModel)]="nutrition.calories" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="calories"></p>
          <p><label class="w3-text-blue"><b>carbohydrate_g</b></label><input required [(ngModel)]="nutrition.carbohydrate_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="carbohydrate_g"></p>
          <p><label class="w3-text-blue"><b>fiber_g</b></label><input required [(ngModel)]="nutrition.fiber_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="fiber_g"></p>
          <p><label class="w3-text-blue"><b>protein_g</b></label><input required [(ngModel)]="nutrition.protein_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="protein_g"></p>
          <p><label class="w3-text-blue"><b>total_fat_g</b></label><input required [(ngModel)]="nutrition.total_fat_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="total_fat_g"></p>
          <p><label class="w3-text-blue"><b>saturated_fat_g</b></label><input required [(ngModel)]="nutrition.saturated_fat_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="saturated_fat_g"></p>
          <p><label class="w3-text-blue"><b>fatty_acids_total_trans_g</b></label><input required [(ngModel)]="nutrition.fatty_acids_total_trans_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="fatty_acids_total_trans_g"></p>
          <p><label class="w3-text-blue"><b>cholesterol_mg</b></label><input required [(ngModel)]="nutrition.cholesterol_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="cholesterol_mg"></p>
          <p><label class="w3-text-blue"><b>sugars_g</b></label><input required [(ngModel)]="nutrition.sugars_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="sugars_g"></p>
          <p><label class="w3-text-blue"><b>water_g</b></label><input required [(ngModel)]="nutrition.water_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="water_g"></p>
          <p><label class="w3-text-blue"><b>vitamin_a_rae_mcg</b></label><input required [(ngModel)]="nutrition.vitamin_a_rae_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_a_rae_mcg"></p>
          <p><label class="w3-text-blue"><b>thiamin_mg</b></label><input required [(ngModel)]="nutrition.thiamin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="thiamin_mg"></p>
          <p><label class="w3-text-blue"><b>riboflavin_mg</b></label><input required [(ngModel)]="nutrition.riboflavin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="riboflavin_mg"></p>
          <p><label class="w3-text-blue"><b>niacin_mg</b></label><input required [(ngModel)]="nutrition.niacin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="niacin_mg"></p>
          <p><label class="w3-text-blue"><b>pantothenic_acid_mg</b></label><input required [(ngModel)]="nutrition.pantothenic_acid_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="pantothenic_acid_mg"></p>
          <p><label class="w3-text-blue"><b>vitamin_b6_mg</b></label><input required [(ngModel)]="nutrition.vitamin_b6_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_b6_mg"></p>
          <p><label class="w3-text-blue"><b>folate_mcg</b></label><input required [(ngModel)]="nutrition.folate_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="folate_mcg"></p>
          <p><label class="w3-text-blue"><b>vitamin_b12_mcg</b></label><input required [(ngModel)]="nutrition.vitamin_b12_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_b12_mcg"></p>
          <p><label class="w3-text-blue"><b>choline_mg</b></label><input required [(ngModel)]="nutrition.choline_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="choline_mg"></p>
          <p><label class="w3-text-blue"><b>vitamin_c_mg</b></label><input required [(ngModel)]="nutrition.vitamin_c_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_c_mg"></p>
          <p><label class="w3-text-blue"><b>vitamin_d_IU</b></label><input required [(ngModel)]="nutrition.vitamin_d_IU" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_d_IU"></p>
          <p><label class="w3-text-blue"><b>vitamin_e_mg</b></label><input required [(ngModel)]="nutrition.vitamin_e_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_e_mg"></p>
          <p><label class="w3-text-blue"><b>vitamin_k_mcg</b></label><input required [(ngModel)]="nutrition.vitamin_k_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="vitamin_k_mcg"></p>
          <p><label class="w3-text-blue"><b>calcium_mg</b></label><input required [(ngModel)]="nutrition.calcium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="calcium_mg"></p>
          <p><label class="w3-text-blue"><b>copper_mg</b></label><input required [(ngModel)]="nutrition.copper_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="copper_mg"></p>
          <p><label class="w3-text-blue"><b>irom_mg</b></label><input required [(ngModel)]="nutrition.irom_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="irom_mg"></p>
          <p><label class="w3-text-blue"><b>magnesium_mg</b></label><input required [(ngModel)]="nutrition.magnesium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="magnesium_mg"></p>
          <p><label class="w3-text-blue"><b>manganese_mg</b></label><input required [(ngModel)]="nutrition.manganese_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="manganese_mg"></p>
          <p><label class="w3-text-blue"><b>phosphorous_mg</b></label><input required [(ngModel)]="nutrition.phosphorous_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="phosphorous_mg"></p>
          <p><label class="w3-text-blue"><b>potassium_mg</b></label><input required [(ngModel)]="nutrition.potassium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="potassium_mg"></p>
          <p><label class="w3-text-blue"><b>selenium_mcg</b></label><input required [(ngModel)]="nutrition.selenium_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="selenium_mcg"></p>
          <p><label class="w3-text-blue"><b>sodium_mg</b></label><input required [(ngModel)]="nutrition.sodium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="sodium_mg"></p>
          <p><label class="w3-text-blue"><b>zink_mg</b></label><input required [(ngModel)]="nutrition.zink_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' name="zink_mg"></p>
          <p class="w3-center">
            <button [disabled]="!formControl.valid" (click)="addNutrition()" class="w3-btn w3-green">Create
              <i class="fa fa-check"></i>
            </button>
          </p>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="w3-container w3-center">
  <div id="id02" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
        <span onclick="document.getElementById('id02').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>Update nutrition form</h2>
      </header>
      <div class="w3-container">
        <form class="w3-container" #formControlEdit="ngForm">
          <p>
            <label class="w3-text-blue">
              <b>Name</b>
            </label>
            <input required [(ngModel)]="selectedNutrition.name" minlength="2" name="name" type="text" style="text-align: center;"
              class="w3-input w3-border w3-light-grey">
          </p>
          <p><label class="w3-text-blue"><b>calories</b></label><input required [(ngModel)]="selectedNutrition.calories" name="calories" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>carbohydrate_g</b></label><input required [(ngModel)]="selectedNutrition.carbohydrate_g" name="carbohydrate_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>fiber_g</b></label><input required [(ngModel)]="selectedNutrition.fiber_g" name="fiber_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>protein_g</b></label><input required [(ngModel)]="selectedNutrition.protein_g" name="protein_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>total_fat_g</b></label><input required [(ngModel)]="selectedNutrition.total_fat_g" name="total_fat_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>saturated_fat_g</b></label><input required [(ngModel)]="selectedNutrition.saturated_fat_g" name="saturated_fat_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>fatty_acids_total_trans_g</b></label><input required [(ngModel)]="selectedNutrition.fatty_acids_total_trans_g" name="fatty_acids_total_trans_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>cholesterol_mg</b></label><input required [(ngModel)]="selectedNutrition.cholesterol_mg" name="cholesterol_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>sugars_g</b></label><input required [(ngModel)]="selectedNutrition.sugars_g" name="sugars_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>water_g</b></label><input required [(ngModel)]="selectedNutrition.water_g" name="water_g" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_a_rae_mcg</b></label><input required [(ngModel)]="selectedNutrition.vitamin_a_rae_mcg" name="vitamin_a_rae_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>thiamin_mg</b></label><input required [(ngModel)]="selectedNutrition.thiamin_mg" name="thiamin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>riboflavin_mg</b></label><input required [(ngModel)]="selectedNutrition.riboflavin_mg" name="riboflavin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>niacin_mg</b></label><input required [(ngModel)]="selectedNutrition.niacin_mg" name="niacin_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>pantothenic_acid_mg</b></label><input required [(ngModel)]="selectedNutrition.pantothenic_acid_mg" name="pantothenic_acid_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_b6_mg</b></label><input required [(ngModel)]="selectedNutrition.vitamin_b6_mg" name="vitamin_b6_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>folate_mcg</b></label><input required [(ngModel)]="selectedNutrition.folate_mcg" name="folate_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_b12_mcg</b></label><input required [(ngModel)]="selectedNutrition.vitamin_b12_mcg" name="vitamin_b12_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>choline_mg</b></label><input required [(ngModel)]="selectedNutrition.choline_mg" name="choline_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_c_mg</b></label><input required [(ngModel)]="selectedNutrition.vitamin_c_mg" name="vitamin_c_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_d_IU</b></label><input required [(ngModel)]="selectedNutrition.vitamin_d_IU" name="vitamin_d_IU" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_e_mg</b></label><input required [(ngModel)]="selectedNutrition.vitamin_e_mg" name="vitamin_e_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>vitamin_k_mcg</b></label><input required [(ngModel)]="selectedNutrition.vitamin_k_mcg" name="vitamin_k_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>calcium_mg</b></label><input required [(ngModel)]="selectedNutrition.calcium_mg" name="calcium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>copper_mg</b></label><input required [(ngModel)]="selectedNutrition.copper_mg" name="copper_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>irom_mg</b></label><input required [(ngModel)]="selectedNutrition.irom_mg" name="irom_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>magnesium_mg</b></label><input required [(ngModel)]="selectedNutrition.magnesium_mg" name="magnesium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>manganese_mg</b></label><input required [(ngModel)]="selectedNutrition.manganese_mg" name="manganese_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>phosphorous_mg</b></label><input required [(ngModel)]="selectedNutrition.phosphorous_mg" name="phosphorous_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>potassium_mg</b></label><input required [(ngModel)]="selectedNutrition.potassium_mg" name="potassium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>selenium_mcg</b></label><input required [(ngModel)]="selectedNutrition.selenium_mcg" name="selenium_mcg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>sodium_mg</b></label><input required [(ngModel)]="selectedNutrition.sodium_mg" name="sodium_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p><label class="w3-text-blue"><b>zink_mg</b></label><input required [(ngModel)]="selectedNutrition.zink_mg" name="zink_mg" type="number" style="text-align: center;" class="w3-input w3-border w3-light-grey" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'></p>
          <p class="w3-center">
            <button [disabled]="!formControlEdit.valid" (click)="updateNutrition()" class="w3-btn w3-blue">Update
              <i class="fa fa-check"></i>
            </button>
          </p>
        </form>
      </div>
    </div>
  </div>
</div>


<div id="id03" class="modal">
  <span onclick="document.getElementById('id03').style.display='none'" class="close" title="Close Modal">×</span>
  <form class="modal-content">
    <div class="container">
      <h1>Delete nutrition</h1>
      <p>Name: {{selectedNutrition?.name}}</p>
      <p>Are you sure you want to delete this nutrition?</p>
      <div class="clearfix">
        <button type="button" onclick="document.getElementById('id03').style.display='none'" class="cancelbtn">Cancel</button>
        <button type="button" (click)="deleteNutrition()" class="deletebtn">Delete</button>
      </div>
    </div>
  </form>
</div>

<div class="search-container w3-center w3-padding">
  <form style="max-width: 200px; margin: auto;" #formControlSearch="ngForm">
    <input style="max-width: 150px; margin: 0; float: left;" class="w3-input" required minlength="2" [(ngModel)]="search" type="text" placeholder="Search by name" name="search">
    <button style="margin: 0;" [disabled]="!formControlSearch.valid" (click)="getNutritions()" class="w3-button">
      <i class="fa fa-search"></i>
    </button>
  </form>
</div>

<div *ngIf="!isLoaded" class="loader"></div>


<div *ngIf="isLoaded" class="w3-padding w3-animate-opacity" style="overflow: auto;">
  <table class="divForTable" style="width:80vw;">
    <thead>
      <tr class="w3-blue">
        <th class="w3-center">Name</th>
        <th class="w3-center">Action
          <button class="w3-btn w3-green" onclick="document.getElementById('id01').style.display='block'">
            <i class="fa fa-plus" aria-hidden="true"></i> Add</button>
        </th>
      </tr>
    </thead>
    <tr *ngFor="let nutrition of nutritions">
      <td class="w3-center">
        <div>{{nutrition.name}}</div>
      </td>
      <td class="w3-center">
        <div class="w3-center">
          <button style="font-family : inherit; width: 100px; height: 40px;" (click)="selectNutrition(2, nutrition)" class="w3-btn w3-blue">
            <i class="fa fa-edit"> Update</i>
          </button>
        </div>
        <div style="padding-top: 4px;"></div>
        <div class="w3-center">
          <button style="font-family : inherit; width: 100px; height: 40px;" (click)="selectNutrition(3, nutrition)" class="w3-btn w3-red">
            <i class="fa fa-trash"> Delete</i>
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>

<div *ngIf="search===''" class="w3-center w3-padding">
  <div class="w3-bar w3-border w3-round">
    <button (click)="previousPage()" [disabled]="currentPage <= 0" class="w3-button">&laquo;</button>
    <p style="display: inline; margin-left: 1vw; margin-right: 1vw;">Page {{currentPage + 1}} of {{numberOfPages}}</p>
    <button (click)="nextPage()" [disabled]="currentPage >= numberOfPages - 1" class="w3-button">&raquo;</button>
    <span style="margin-left: 0.5vw">Jump to </span>
    <input style="width: 6vw; margin-right: 1vw;" type="number" (keyup.enter)="jumpToPage($event)">
    <span style="margin-left: 0.5vw;">Per page
      <select style="margin-right: 1vw" [(ngModel)]="currnetLimit" name="option" (ngModelChange)="onLimitChange($event)">
        <option value="4">4</option>
        <option value="8">8</option>
        <option value="12">12</option>
      </select>
    </span>

  </div>
</div>

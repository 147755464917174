export class Dri {
  _id?: String;
  user_id: String;
  name: String = "";
  active: Boolean;
  calcium_mg_max: number;
  calcium_mg: number;
  calories_max: number;
  calories: number;
  carbohydrate_g_max: number;
  carbohydrate_g: number;
  cholesterol_mg_max: number;
  cholesterol_mg: number;
  choline_mg_max: number;
  choline_mg: number;
  copper_mg_max: number;
  copper_mg: number;
  fatty_acids_total_trans_g_max: number;
  fatty_acids_total_trans_g: number;
  fiber_g_max: number;
  fiber_g: number;
  folate_mcg_max: number;
  folate_mcg: number;
  irom_mg_max: number;
  irom_mg: number;
  magnesium_mg_max: number;
  magnesium_mg: number;
  manganese_mg_max: number;
  manganese_mg: number;
  niacin_mg_max: number;
  niacin_mg: number;
  pantothenic_acid_mg_max: number;
  pantothenic_acid_mg: number;
  phosphorous_mg_max: number;
  phosphorous_mg: number;
  potassium_mg_max: number;
  potassium_mg: number;
  protein_g_max: number;
  protein_g: number;
  riboflavin_mg_max: number;
  riboflavin_mg: number;
  saturated_fat_g_max: number;
  saturated_fat_g: number;
  selenium_mcg_max: number;
  selenium_mcg: number;
  sodium_mg_max: number;
  sodium_mg: number;
  sugars_g_max: number;
  sugars_g: number;
  thiamin_mg_max: number;
  thiamin_mg: number;
  total_fat_g_max: number;
  total_fat_g: number;
  vitamin_a_rae_mcg_max: number;
  vitamin_a_rae_mcg: number;
  vitamin_b12_mcg_max: number;
  vitamin_b12_mcg: number;
  vitamin_b6_mg_max: number;
  vitamin_b6_mg: number;
  vitamin_c_mg_max: number;
  vitamin_c_mg: number;
  vitamin_d_IU_max: number;
  vitamin_d_IU: number;
  vitamin_e_mg_max: number;
  vitamin_e_mg: number;
  vitamin_k_mcg_max: number;
  vitamin_k_mcg: number;
  water_g_max: number;
  water_g: number;
  zink_mg_max: number;
  zink_mg: number;
}

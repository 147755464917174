<div *ngIf="!isLoaded" class="loader"></div>


<div *ngIf="isLoaded" class="w3-padding w3-animate-opacity" style="overflow: auto;">
  <table class="divForTable" style="width:80vw;">
    <thead>
      <tr class="w3-blue">
        <th class="w3-center">Name</th>
        <th class="w3-center">Status</th>
        <th class="w3-center">Action
          <button class="w3-btn w3-green" (click)="openAddForm()">
            <i class="fa fa-plus" aria-hidden="true"></i> Add</button>
        </th>
      </tr>
    </thead>
    <tr *ngFor="let dri of dris">
      <td class="w3-center">
        <div>{{dri.name}}</div>
      </td>
      <td class="w3-center">
        <div class="w3-text-green" *ngIf="dri.active;else not_active">
          <b>Currently active</b>
        </div>

        <ng-template #not_active>
          <button (click)="setAsActive(dri)" class="w3-btn w3-blue">
            <i class="fa fa-edit"> Set as current</i>
          </button>
        </ng-template>
      </td>
      <td class="w3-center">
        <div class="w3-center">
          <button style="width: 100px; height: 38px;" (click)="openUpdateForm(dri)" class="w3-btn w3-blue">
            <i class="fa fa-edit"> Update</i>
          </button>
        </div>
        <div style="padding-top: 4px;"></div>
        <div *ngIf="!dri.active" class="w3-center">
          <button style="width: 100px; height: 38px;" (click)="openDeleteForm(dri)" class="w3-btn w3-red">
            <i class="fa fa-trash"> Delete</i>
          </button>
        </div>
      </td>
    </tr>
  </table>
</div>

<p *ngIf="isLoaded" class="w3-center w3-padding w3-animate-opacity">For more specific DRIs and IUs use the
    <a class="w3-btn w3-round-xxlarge w3-blue" href="https://www.nal.usda.gov/legacy/fnic/dri-calculator/index.php" target="_blank">DRI calculator</a>
</p>


<div class="w3-container w3-center">
  <div id="id01" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
        <span onclick="document.getElementById('id01').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>New DRI form</h2>
      </header>
      <div class="w3-container">
        <form class="w3-container" #formControl="ngForm">
          <p>
            <label class="w3-text-blue">
              <b>Name</b>
            </label>
            <input required [(ngModel)]="dri.name" name="name" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          </p>
          <hr class="separator">

          <div class="w3-text-blue"><b>Calories</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="calories" [(ngModel)]="dri.calories" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="calories_max" [(ngModel)]="dri.calories_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Carbohydrate (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="carbohydrate_g" [(ngModel)]="dri.carbohydrate_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="carbohydrate_g_max" [(ngModel)]="dri.carbohydrate_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Fiber (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="fiber_g" [(ngModel)]="dri.fiber_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="fiber_g_max" [(ngModel)]="dri.fiber_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Protein (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="protein_g" [(ngModel)]="dri.protein_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="protein_g_max" [(ngModel)]="dri.protein_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Total fat  (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="total_fat_g" [(ngModel)]="dri.total_fat_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="total_fat_g_max" [(ngModel)]="dri.total_fat_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Saturated fat (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="saturated_fat_g" [(ngModel)]="dri.saturated_fat_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="saturated_fat_g_max" [(ngModel)]="dri.saturated_fat_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Fatty acids total trans (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="fatty_acids_total_trans_g" [(ngModel)]="dri.fatty_acids_total_trans_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="fatty_acids_total_trans_g_max" [(ngModel)]="dri.fatty_acids_total_trans_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Cholesterol (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="cholesterol_mg" [(ngModel)]="dri.cholesterol_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="cholesterol_mg_max" [(ngModel)]="dri.cholesterol_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Sugars (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="sugars_g" [(ngModel)]="dri.sugars_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="sugars_g_max" [(ngModel)]="dri.sugars_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Water (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="water_g" [(ngModel)]="dri.water_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="water_g_max" [(ngModel)]="dri.water_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1L &#8776; 1000g</p><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin A RAE (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_a_rae_mcg" [(ngModel)]="dri.vitamin_a_rae_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_a_rae_mcg_max" [(ngModel)]="dri.vitamin_a_rae_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Thiamin - B1  (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="thiamin_mg" [(ngModel)]="dri.thiamin_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="thiamin_mg_max" [(ngModel)]="dri.thiamin_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Riboflavin - B2 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="riboflavin_mg" [(ngModel)]="dri.riboflavin_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="riboflavin_mg_max" [(ngModel)]="dri.riboflavin_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Niacin - B3 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="niacin_mg" [(ngModel)]="dri.niacin_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="niacin_mg_max" [(ngModel)]="dri.niacin_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Pantothenic acid - B5 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="pantothenic_acid_mg" [(ngModel)]="dri.pantothenic_acid_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="pantothenic_acid_mg_max" [(ngModel)]="dri.pantothenic_acid_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin B6 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_b6_mg" [(ngModel)]="dri.vitamin_b6_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_b6_mg_max" [(ngModel)]="dri.vitamin_b6_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Folate - B9 (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="folate_mcg" [(ngModel)]="dri.folate_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="folate_mcg_max" [(ngModel)]="dri.folate_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin B12 (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_b12_mcg" [(ngModel)]="dri.vitamin_b12_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_b12_mcg_max" [(ngModel)]="dri.vitamin_b12_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Choline (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="choline_mg" [(ngModel)]="dri.choline_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="choline_mg_max" [(ngModel)]="dri.choline_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1g = 1000mg</p><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin C (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_c_mg" [(ngModel)]="dri.vitamin_c_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_c_mg_max" [(ngModel)]="dri.vitamin_c_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin D (IU)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_d_IU" [(ngModel)]="dri.vitamin_d_IU" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_d_IU_max" [(ngModel)]="dri.vitamin_d_IU_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1mcg = 40IU</p><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin E (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_e_mg" [(ngModel)]="dri.vitamin_e_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_e_mg_max" [(ngModel)]="dri.vitamin_e_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin K (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_k_mcg" [(ngModel)]="dri.vitamin_k_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_k_mcg_max" [(ngModel)]="dri.vitamin_k_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Calcium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="calcium_mg" [(ngModel)]="dri.calcium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="calcium_mg_max" [(ngModel)]="dri.calcium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Copper (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="copper_mg" [(ngModel)]="dri.copper_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="copper_mg_max" [(ngModel)]="dri.copper_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1000mcg = 1mg</p><hr class="separator">
          <div class="w3-text-blue"><b>Iron (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="irom_mg" [(ngModel)]="dri.irom_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="irom_mg_max" [(ngModel)]="dri.irom_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Magnesium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="magnesium_mg" [(ngModel)]="dri.magnesium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="magnesium_mg_max" [(ngModel)]="dri.magnesium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Manganese (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="manganese_mg" [(ngModel)]="dri.manganese_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="manganese_mg_max" [(ngModel)]="dri.manganese_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Phosphorous (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="phosphorous_mg" [(ngModel)]="dri.phosphorous_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="phosphorous_mg_max" [(ngModel)]="dri.phosphorous_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1g = 1000mg</p><hr class="separator">
          <div class="w3-text-blue"><b>Potassium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="potassium_mg" [(ngModel)]="dri.potassium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="potassium_mg_max" [(ngModel)]="dri.potassium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Selenium (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="selenium_mcg" [(ngModel)]="dri.selenium_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="selenium_mcg_max" [(ngModel)]="dri.selenium_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Sodium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="sodium_mg" [(ngModel)]="dri.sodium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="sodium_mg_max" [(ngModel)]="dri.sodium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Zink (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="zink_mg" [(ngModel)]="dri.zink_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="zink_mg_max" [(ngModel)]="dri.zink_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">

          <p class="w3-center">
            <button [disabled]="!formControl.valid" (click)="add()" class="w3-btn w3-green">Create
              <i class="fa fa-check"></i>
            </button>
          </p>

          <p class="w3-center w3-text-grey">
            * All fields are required<br>
            * If there is no UI value, input same as DRI<br>
            * DRI must be greater than 0 (zero)<br>
            * UI must be equal or greater than DRI<br>
            * Watch for conversion from units
          </p>
        </form>
      </div>
    </div>
  </div>
</div>

<div class="w3-container w3-center">
  <div id="id02" class="w3-modal">
    <div class="w3-modal-content w3-animate-top w3-card-4">
      <header class="w3-container w3-blue">
        <span onclick="document.getElementById('id02').style.display='none'" class="w3-button w3-display-topright">&times;</span>
        <h2>Update DRI form</h2>
      </header>
      <div class="w3-container">
        <form class="w3-container" #formControlEdit="ngForm">
          <p>
            <label class="w3-text-blue">
              <b>Name</b>
            </label>
            <input required [(ngModel)]="dri.name" name="name" type="text" style="text-align: center;" class="w3-input w3-border w3-light-grey">
          </p>
          <hr class="separator">

          <div class="w3-text-blue"><b>Calories</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="calories" [(ngModel)]="dri.calories" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="calories_max" [(ngModel)]="dri.calories_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Carbohydrate (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="carbohydrate_g" [(ngModel)]="dri.carbohydrate_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="carbohydrate_g_max" [(ngModel)]="dri.carbohydrate_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Fiber (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="fiber_g" [(ngModel)]="dri.fiber_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="fiber_g_max" [(ngModel)]="dri.fiber_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Protein (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="protein_g" [(ngModel)]="dri.protein_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="protein_g_max" [(ngModel)]="dri.protein_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Total fat (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="total_fat_g" [(ngModel)]="dri.total_fat_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="total_fat_g_max" [(ngModel)]="dri.total_fat_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Saturated fat (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="saturated_fat_g" [(ngModel)]="dri.saturated_fat_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="saturated_fat_g_max" [(ngModel)]="dri.saturated_fat_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Fatty acids total trans (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="fatty_acids_total_trans_g" [(ngModel)]="dri.fatty_acids_total_trans_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="fatty_acids_total_trans_g_max" [(ngModel)]="dri.fatty_acids_total_trans_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Cholesterol (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="cholesterol_mg" [(ngModel)]="dri.cholesterol_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="cholesterol_mg_max" [(ngModel)]="dri.cholesterol_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Sugars (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="sugars_g" [(ngModel)]="dri.sugars_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="sugars_g_max" [(ngModel)]="dri.sugars_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Water (g)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="water_g" [(ngModel)]="dri.water_g" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="water_g_max" [(ngModel)]="dri.water_g_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1L &#8776; 1000g</p><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin A RAE (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_a_rae_mcg" [(ngModel)]="dri.vitamin_a_rae_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_a_rae_mcg_max" [(ngModel)]="dri.vitamin_a_rae_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Thiamin - B1 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="thiamin_mg" [(ngModel)]="dri.thiamin_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="thiamin_mg_max" [(ngModel)]="dri.thiamin_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Riboflavin - B2 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="riboflavin_mg" [(ngModel)]="dri.riboflavin_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="riboflavin_mg_max" [(ngModel)]="dri.riboflavin_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Niacin - B3 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="niacin_mg" [(ngModel)]="dri.niacin_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="niacin_mg_max" [(ngModel)]="dri.niacin_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Pantothenic acid - B5 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="pantothenic_acid_mg" [(ngModel)]="dri.pantothenic_acid_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="pantothenic_acid_mg_max" [(ngModel)]="dri.pantothenic_acid_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin B6 (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_b6_mg" [(ngModel)]="dri.vitamin_b6_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_b6_mg_max" [(ngModel)]="dri.vitamin_b6_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Folate - B9 (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="folate_mcg" [(ngModel)]="dri.folate_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="folate_mcg_max" [(ngModel)]="dri.folate_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin B12 (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_b12_mcg" [(ngModel)]="dri.vitamin_b12_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_b12_mcg_max" [(ngModel)]="dri.vitamin_b12_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Choline (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="choline_mg" [(ngModel)]="dri.choline_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="choline_mg_max" [(ngModel)]="dri.choline_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1g = 1000mg</p><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin C (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_c_mg" [(ngModel)]="dri.vitamin_c_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_c_mg_max" [(ngModel)]="dri.vitamin_c_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin D (IU)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_d_IU" [(ngModel)]="dri.vitamin_d_IU" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_d_IU_max" [(ngModel)]="dri.vitamin_d_IU_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1mcg = 40IU</p><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin E (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_e_mg" [(ngModel)]="dri.vitamin_e_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_e_mg_max" [(ngModel)]="dri.vitamin_e_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Vitamin K (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="vitamin_k_mcg" [(ngModel)]="dri.vitamin_k_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="vitamin_k_mcg_max" [(ngModel)]="dri.vitamin_k_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Calcium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="calcium_mg" [(ngModel)]="dri.calcium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="calcium_mg_max" [(ngModel)]="dri.calcium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Copper (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="copper_mg" [(ngModel)]="dri.copper_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="copper_mg_max" [(ngModel)]="dri.copper_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1000mcg = 1mg</p><hr class="separator">
          <div class="w3-text-blue"><b>Iron (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="irom_mg" [(ngModel)]="dri.irom_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="irom_mg_max" [(ngModel)]="dri.irom_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Magnesium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="magnesium_mg" [(ngModel)]="dri.magnesium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="magnesium_mg_max" [(ngModel)]="dri.magnesium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Manganese (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="manganese_mg" [(ngModel)]="dri.manganese_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="manganese_mg_max" [(ngModel)]="dri.manganese_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Phosphorous (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="phosphorous_mg" [(ngModel)]="dri.phosphorous_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="phosphorous_mg_max" [(ngModel)]="dri.phosphorous_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div>
          <p class="w3-center w3-text-grey">1g = 1000mg</p><hr class="separator">
          <div class="w3-text-blue"><b>Potassium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="potassium_mg" [(ngModel)]="dri.potassium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="potassium_mg_max" [(ngModel)]="dri.potassium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Selenium (mcg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="selenium_mcg" [(ngModel)]="dri.selenium_mcg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="selenium_mcg_max" [(ngModel)]="dri.selenium_mcg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Sodium (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="sodium_mg" [(ngModel)]="dri.sodium_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="sodium_mg_max" [(ngModel)]="dri.sodium_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">
          <div class="w3-text-blue"><b>Zink (mg)</b><div class="main-div"><div class="left-div"><label class="w3-text-blue"><b>DRI</b></label><input required name="zink_mg" [(ngModel)]="dri.zink_mg" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)' type="number" class="small-input w3-input w3-border w3-light-grey"></div><div><label class="w3-text-blue"><b>UI</b></label><input required name="zink_mg_max" [(ngModel)]="dri.zink_mg_max" onkeypress='return event.charCode == 46 || (event.charCode >= 48 && event.charCode <= 57)'type="number" class="small-input w3-input w3-border w3-light-grey"></div></div></div><hr class="separator">

          <p class="w3-center">
            <button [disabled]="!formControlEdit.valid" (click)="update()" class="w3-btn w3-blue">Update
              <i class="fa fa-check"></i>
            </button>
          </p>
          <p class="w3-center w3-text-grey">
            * All fields are required<br>
            * If there is no UI value, input same as DRI<br>
            * DRI must be greater than 0 (zero)<br>
            * UI must be equal or greater than DRI<br>
            * Watch for conversion from units
          </p>
        </form>
      </div>
    </div>
  </div>
</div>


<div id="id03" class="modal">
  <span onclick="document.getElementById('id03').style.display='none'" class="close" title="Close Modal">×</span>
  <form class="modal-content">
    <div class="container">
      <h1>Delete DRI</h1>
      <p>Name: {{dri?.name}}</p>
      <p>Are you sure you want to delete this DRI?</p>

      <div class="clearfix">
        <button type="button" onclick="document.getElementById('id03').style.display='none'" class="cancelbtn">Cancel</button>
        <button type="button" (click)="delete()" class="deletebtn">Delete</button>
      </div>
    </div>
  </form>
</div>
